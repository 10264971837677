import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import './style.scss'

export default function BtnPrevious({ link, field = '', value = '', title, visible = true, utmStep = null }) {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const userData = useSelector(state => state.userData)

	// FUNCTIONS
	const handleOnClick = () => {
		if (userData?.data?.id) {
			dispatch({ type: 'PAGES_UPDATE_USER_DATA', payload: { ...userData.data, [field]: value ? value : '', utmStep: utmStep ? utmStep : userData.data.utmStep } })

			if (userData.data[field] !== undefined) {
				navigate(link, { replace: true })
			}
		}
	}

	if (visible) {
		if (!field.length && utmStep === null) {
			return <Link to={link} replace={true} className="btn btn-arrow btn-previous">{title}</Link>
		}

		return <button className="btn btn-arrow btn-previous" onClick={handleOnClick}>{title}</button>
	} else {
		<></>
	}
}
