import { useState } from 'react';
import { useInterval } from "../../actions/generalUtils"
import AnimIcon from "./../controllers/AnimIcon"
import helper from '../../assets/lotties/helper.json'

export default function FramedAnimIcon(props) {
	const [play,setPlay] = useState(true)

	useInterval(() => {
		setPlay(play => !play)
	}, 5940)

	return (
		<div className={`animHelperFrame ${props.className ? props.className : ""}`}>
			<AnimIcon loop={true} autoplay={true} json={helper} width={props.width ? props.width : 28} toggled={true} speed={play ? 1 : 0}/>
		</div>
	)
}
