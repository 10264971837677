import { useTranslation } from 'react-i18next'
import BtnPrevious from '../components/buttons/BtnPrevious'
import Board from '../layouts/Board'
import Slide from '../layouts/Slide'
import ImgLockPass from '../assets/images/lockself/logoLockpass.png'
import ImgLockTransfer from '../assets/images/lockself/logoLocktransfer.png'
import ImgLockFiles from '../assets/images/lockself/logoLockfiles.png'
import { ReactComponent as IconKey } from '../assets/icons/icon-key.svg'
import { ReactComponent as IconArrows } from '../assets/icons/icon-arrows.svg'
import { ReactComponent as IconFolder } from '../assets/icons/icon-folder.svg'
import { ReactComponent as IconBlocks } from '../assets/icons/icon-blocks.svg'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BtnFilled from '../components/buttons/BtnFilled'
import { useNavigate } from 'react-router-dom'
import Pagination from '../components/Pagination'

export default function Solutions() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [t] = useTranslation()
	const userData = useSelector(state => state.userData)
	const [solutions, setSolutions] = useState([])
	const [options, setOptions] = useState([
		{
			selected: false,
			id: 'LP',
			Icon: IconKey
		},
		{
			selected: false,
			id: 'LT',
			Icon: IconArrows
		},
		{
			selected: false,
			id: 'LF',
			Icon: IconFolder
		}
	])

	// FUNCTIONS
	const handleOptionsSelect = (option) => {
		setSolutions(solutions.includes(option) ? solutions.filter(solution => solution !== option) : [...solutions, option])
	}

	const handleSubmitOptions = () => {
		validateStep()
	}

	const validateStep = useCallback(
		() => {
			dispatch({ type: 'PAGES_UPDATE_USER_DATA', payload: {
				...userData.data,
				solution: solutions.length > 0 ? solutions.join('-') : '?',
				utmStep: userData.data.estimatedUser === '1-25' ? '7' : '4'
			} })

			if (userData.data.estimatedUser === '1-25') {
				navigate('/subscription?utm_step=7', { replace: true })
			} else {
				navigate('/appointement?utm_step=4', { replace: true })
			}
		}, [dispatch, navigate, solutions, userData]
	)

	// EFFECTS
	useEffect(() => {
		if (solutions.length === options.length) {
			validateStep()
		}
	}, [solutions, options, validateStep])

	// JSX FUNCTIONS
	const Options = () => (
		options.map(({ selected, id, Icon }, i) => (
			<div className={`item item--${id.toLowerCase()} ${selected ? 'is-selected' : ''}`} key={i}>
				<button
					type="button"
					className="btn"
					onClick={
						() => {
							handleOptionsSelect(id)
							setOptions(options.map(option => option.id === id ? { ...option, selected: !option.selected } : option))
						}
					}
				></button>
				<div className="img-container">
					<Icon />
				</div>
				<div className="item-content">
					<p className="title">{t(`pages.solutions.options.list.${id}.title`)}</p>
					<p className="text">{t(`pages.solutions.options.list.${id}.text`)}</p>
				</div>
			</div>
		))
	)

	return (
		<>
            <Board classes={'context-solutions'}>
				<Pagination text={t('pages.solutions.pagination.text')} width="calc((100% / 5) * 3)" />
				<div className="content">
					<div className="img-container">
						<IconBlocks />
					</div>
					<p className="title">{t('pages.solutions.options.title')}</p>
					<div className="options">
						<Options />
					</div>
					<BtnFilled
						title={t(solutions.length > 0 ? 'common.buttons.continue' : 'common.buttons.dontKnowYet')}
						classes={solutions.length > 0 ? '' : 'is-disabled'}
						onClickMethod={handleSubmitOptions}
					/>
				</div>
				<BtnPrevious title={t('common.buttons.previous')} link={'/perimeters?utm_step=2'} field="estimatedUser" value="" utmStep="2" />
            </Board>
            <Slide
				title={t('pages.solutions.slide.title')}
				classes={'context-solutions'}
			>
				<div className="img-container">
					<img src={ImgLockPass} alt="" />
				</div>
				<div className="img-container">
					<img src={ImgLockTransfer} alt="" />
				</div>
				<div className="img-container">
					<img src={ImgLockFiles} alt="" />
				</div>
            </Slide>
        </>
	)
}
