export default function Input({ label, type = 'text', name, id = name, onChangeMethod, placeholder = label, value, autoFocus = false, error = { isActive: false, text: '' }, invalid = { status: false, text: '' } }) {
    return (
		<div className={`form-input-wrapper ${type === 'checkbox' ? 'form-input-wrapper--checkbox' : ''}`}>
			{(label && type !== "checkbox") && <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} />}
			<input
				autoFocus={autoFocus}
				className={`input ${error.isActive || invalid.status ? 'input--error' : ''}`}
				type={type}
				name={name}
				id={id}
				placeholder={placeholder}
				value={value}
				onChange={onChangeMethod}
				required
			/>
			{(label && type === "checkbox") && <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} />}
			{error.isActive && <div className="form-error">{error.text}</div>}
			{(!error.isActive && invalid.status) && <div className="form-error">{invalid.text}</div>}
		</div>
    )
}
