import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { windowReload } from '../actions/generalUtils'
import Loader from './animations/Loader'
import './style.scss'

export default function Layout() {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const [searchParams] = useSearchParams()
	const userData = useSelector(state => state.userData)
	let classes = []

	if (pathname.includes('calendar')) {
		classes = [...classes, 'layout--calendar']
	}

	if (pathname.includes('success-message')) {
		classes = [...classes, 'layout--success-message']
	}

	// EFFECTS
	useEffect(() => {
		if (
			(!localStorage.getItem('userData') && pathname !== '/' && (!searchParams.get('utm_step') || searchParams.get('utm_step') !== '9')) ||
			userData.errorCode === 404 ||
			userData.errorCode === 500
		) {
			navigate('/', { replace: true })
		}
	}, [searchParams, navigate, userData, pathname])

	if (!pathname.includes('success-message')) {
		if (userData?.errorCode === 404 && !localStorage.getItem('isSessionExpired')) {
			localStorage.setItem('isSessionExpired', true)
		}

		windowReload()
	}

    return (
        <div className={`layout ${classes.join(' ')}`}>
			{userData.loading && <Loader />}
            {(userData.loading === undefined || !userData.loading) && <Outlet />}
        </div>
    )
}
