import FramedAnimIcon from './animations/AnimHelper'

export default function Popin({ text }) {
	return (
		<div className="popin">
			<FramedAnimIcon />
			<div className="popin-content">{text}</div>
		</div>
	)
}
