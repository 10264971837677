import CompanyAltran from '../assets/images/companies/altran.png'
import CompanyAphp from '../assets/images/companies/aphp.png'
import CompanyBnp from '../assets/images/companies/bnp.png'
import CompanyBouygues from '../assets/images/companies/bouygues.png'
import CompanyEy from '../assets/images/companies/ey.png'
import CompanyRte from '../assets/images/companies/rte.png'
import CompanySncf from '../assets/images/companies/sncf.png'

export default function Gallery() {
	return (
		<div className="gallery">
			<div className="img-container img-altran">
				<img src={CompanyAltran} alt="" />
			</div>
			<div className="img-container img-aphp">
				<img src={CompanyAphp} alt="" />
			</div>
			<div className="img-container img-bnp">
				<img src={CompanyBnp} alt="" />
			</div>
			<div className="img-container img-bouygues">
				<img src={CompanyBouygues} alt="" />
			</div>
			<div className="img-container img-ey">
				<img src={CompanyEy} alt="" />
			</div>
			<div className="img-container img-rte">
				<img src={CompanyRte} alt="" />
			</div>
			<div className="img-container img-sncf">
				<img src={CompanySncf} alt="" />
			</div>
		</div>
	)
}
