import { useTranslation } from 'react-i18next'
import Board from '../layouts/Board'
import Slide from '../layouts/Slide'
import { useDispatch, useSelector } from 'react-redux'
import Gallery from '../components/Gallery'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as IconMembers } from '../assets/icons/icon-members.svg'

export default function Perimeters() {
    const navigate = useNavigate()
	const dispatch = useDispatch()
	const [t] = useTranslation()
	const choices = ['1-25', '25-100', '100-500', '500-1000', '1000+']
	const userData = useSelector(state => state.userData)

    //  FUNCTIONS
	const handleChoiceSelect = (choice) => {
		if (choice !== null && choice !== undefined) {
			dispatch({ type: 'PAGES_UPDATE_USER_DATA', payload: { ...userData.data, estimatedUser: choice, utmStep: '3' } })
			navigate('/solutions?utm_step=3', { replace: true })
		}
	}

	// JSX FUNCTIONS
	const Choices = () => (
		choices.map((choice, i) => (
			<div className="item" key={i}>
				<button
					type="button"
					className="btn"
					onClick={() => handleChoiceSelect(choice)}
				></button>
				<p className="title">{t(`pages.information.choices.list.${choice}`)}</p>
			</div>
		))
	)

    return (
        <>
            <Board classes={'context-information'}>
				<div className="content">
					<div className="img-container">
						<IconMembers />
					</div>
					<p className="title">{t('pages.information.choices.title', { organizationName: userData.data.organizationName })}</p>
					<div className="choices">
						<Choices />
					</div>
				</div>
				<Link to="/?utm_step=1" replace={true} className="btn btn-arrow btn-previous">{t('common.buttons.previous')}</Link>
            </Board>
            <Slide
				title={t('pages.information.slide.title2')}
				classes={'context-information'}
			>
                <Gallery />
            </Slide>
        </>
    )
}
