import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import BtnFilled from '../components/buttons/BtnFilled'
import Form from '../components/Form'
import Input from '../components/Input'
import Pagination from '../components/Pagination'
import Board from '../layouts/Board'
import Slide from '../layouts/Slide'
import { useDispatch, useSelector } from 'react-redux'
import BtnPrevious from '../components/buttons/BtnPrevious'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

export default function Personal() {
	const phoneInputRef = useRef(null)
	const dispatch = useDispatch()
	const [t] = useTranslation()
	const navigate = useNavigate()
	const userData = useSelector(state => state.userData)
	const [phoneNumberError, setPhoneNumberError] = useState(false)

	const [data, setData] = useState({
		firstName: userData.data?.firstName ? userData.data?.firstName : '',
		lastName: userData.data?.lastName ? userData.data?.lastName : '',
		phoneNumber: userData.data?.phoneNumber ? userData.data?.phoneNumber : ''
	})

	const [canRunPhoneValidityCheck, setCanRunPhoneValidityCheck] = useState(true)

	// FUNCTIONS
	const handleOnSubmit = (event) => {
		event.preventDefault()

		if (!phoneNumberError && data.phoneNumber.length > 0 && data.lastName.length > 0 && data.firstName.length > 0) {
			dispatch({ type: 'PAGES_UPDATE_USER_DATA', payload: { ...userData.data, ...data, email: userData.data.email, utmStep: '9' } })
			navigate('/success-message?utm_step=9', { replace: true })
		} else {
			setPhoneNumberError(true)
			phoneInputRef.current.focus()
		}
	}

	const handleOnChange = ({ target: { name, value } }) => {
		setData({
			...data,
			[name]: value
		})
	}

	const formatPhoneNumber = (phoneNumber, format) => {
		let result = ""
		let currentChar = 0

		for (let i = 0; i < format.length; i++) {
			if (format[i] === ".") {
				result += phoneNumber[currentChar]
				currentChar++
			} else {
				result += format[i]
			}
		}

		return result
	}

	// PHONE INPUT ON CHANGE METHOD
	const handleInputTelOnChange = (_value, countryFormat, event, formattedValue) => {
		if (event.key !== 'Enter') {
			// Buckle up ! We have to fix a user's common mistake.
			// People often don't pay attention to the phone number format and tend to start their number with 0,
			// which cause their input to overflow the maximal digit limit. The consequence is simple, our sales
			// don't have the correct phone number, as the last digit will be missing. The next part read the new input value
			// and, if it overflowed, check if the first digit was a 0, in which case we remove it.

			// This means *inhale loudly*...
			// That we must deactivate the maximum characters limit (or else the onChange event won't be triggered and we'll
			// never know what the last number is), do our own maximum characters limitation check and THEN detect if the 
			// first number that isn't part of the format is a 0, in which case we must rewrite the whole phone number with
			// the first 0 replaced with the last number, while keeping the correct format.
			let digitMaxQuantity = 0
			for (let i = 0; i < formattedValue.length; i++) {
				if (countryFormat.format[i] === countryFormat.format[1]) digitMaxQuantity++
			}

			if (_value.length === digitMaxQuantity + 1) {
				if (_value[countryFormat.dialCode.length] === "0") {
					const newValue = _value.substring(0, countryFormat.dialCode.length) + _value.substring(countryFormat.dialCode.length + 1, _value.length)
					setData({...data, phoneNumber: formatPhoneNumber(newValue, countryFormat.format)})

				}
			} else if (_value.length <= digitMaxQuantity) {
				setData({ ...data, phoneNumber: formattedValue })
			}
		}

		return setCanRunPhoneValidityCheck(true)
	}

	const handleCheckPhoneValidity = (value, country) => {
		if (!canRunPhoneValidityCheck) return

		setCanRunPhoneValidityCheck(false)
		const { format, dialCode } = country

		const valueDial = value.substring(0, dialCode.length)
		const matchingDials = dialCode === valueDial

		const formattedValue = value.split("").reduce((accumulator, item) => {
			return accumulator.replace(".", item)
		}, format).replaceAll(".", "")
		const matchingFormat = format.length === formattedValue.length

		if (!matchingDials) {
			return setPhoneNumberError(true)
		}
		if (dialCode === "33" && !matchingFormat) {
			return setPhoneNumberError(true)
		}
		if (phoneNumberError) {
			return setPhoneNumberError(false)
		}

		return
	}

	return (
		<>
            <Board classes="context-subscription context-personal">
				<Pagination text={t('pages.subscription.started.pagination.text')} width="calc((100% / 3) * 2" />
				<div className="content-container">
					<div className="content-header">
						<p className="title">{t('pages.subscription.started.board.title')}</p>
						<p className="text">{t('pages.subscription.started.board.text')}</p>
					</div>
					<Form onSubmitMethod={handleOnSubmit}>
						<div className="form-tel-container">
							<PhoneInput
								inputProps={{
									autoFocus: true,
									required: true,
									ref: phoneInputRef,
									value: data.phoneNumber?.length > 0 ? data.phoneNumber : '+33'
								}}
								specialLabel={t('forms.phoneNumber.label')}
								placeholder={t('forms.phoneNumber.placeholder')}
								containerClass="form-input-wrapper form-input-tel-wrapper"
								inputClass={`input ${phoneNumberError ? 'input--error' : ''}`}
								country={'fr'}
								onChange={handleInputTelOnChange}
								isValid={handleCheckPhoneValidity}
								enableLongNumbers
							/>
							{phoneNumberError && <div className="form-error">{t('forms.phoneNumber.errors.required')}</div>}
						</div>
						<div className="form-row">
							<Input
								onChangeMethod={handleOnChange}
								name="lastName"
								label={t('forms.name.label')}
								value={data.lastName}
								placeholder={t('forms.name.placeholder')}
							/>
							<Input
								onChangeMethod={handleOnChange}
								name="firstName"
								label={t('forms.firstname.label')}
								value={data.firstName}
								placeholder={t('forms.firstname.placeholder')}
							/>
						</div>
						<div className="form-footer">
							<BtnFilled type="submit" title={t('common.buttons.createAccount')} />
						</div>
					</Form>
				</div>
				<BtnPrevious title={t('common.buttons.previous')} link={'/subscription?utm_step=7'} field="email" value="" utmStep="7" />
            </Board>
            <Slide
				title={t('pages.subscription.started.slide.title')}
				classes={'context-subscription context-personal'}
			>
				<p className="multicolor-text big-text" dangerouslySetInnerHTML={{ __html: t('pages.subscription.started.slide.bigText') }} />
            </Slide>
        </>
	)
}
