import { createRef, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import FirstTestimony from '../assets/images/slider/testimony-1.png'
import SecondTestimony from '../assets/images/slider/testimony-2.png'
import ThirdTestimony from '../assets/images/slider/testimony-3.png'

export default function Slider() {
	const slidesRef = useRef(null)
	const [t] = useTranslation()
	const slides = [
		{
			id: 0,
			status: 'is-current',
			text: t('pages.appointement.slide.slider.slide1.text'),
			Icon: FirstTestimony,
			author: t('pages.appointement.slide.slider.slide1.author')
		},
		{
			id: 1,
			status: '',
			text: t('pages.appointement.slide.slider.slide2.text'),
			Icon: SecondTestimony,
			author: t('pages.appointement.slide.slider.slide2.author')
		},
		{
			id: 2,
			status: '',
			text: t('pages.appointement.slide.slider.slide3.text'),
			Icon: ThirdTestimony,
			author: t('pages.appointement.slide.slider.slide3.author')
		}
	]
	const slideArrRef = slides.map(() => createRef(null))
	const bulletArrRef = slides.map(() => createRef(null))

	// FUNCTIONS
	const goToSlide = (id) => {
		const slideOffsetLeft = slideArrRef[id].current.offsetLeft

		slides.forEach((_slide, i) => {
			// remove is-current class from previously current slide
			slideArrRef[i].current.classList.remove('is-current')
			// remove is-current class from previously current bullet
			bulletArrRef[i].current.classList.remove('is-current')
		})

		// set translate value based on offsetLeft property of selected slide
		slidesRef.current.style.transform = `translateX(${slideOffsetLeft < 0 ? '' : '-'}${slideOffsetLeft}px)`

		// add is-current class to selected slide and related bullet
		slideArrRef[id].current.classList.add('is-current')
		bulletArrRef[id].current.classList.add('is-current')
	}

	return (
		<div className="slider">
			<div className="slides-wrapper">
				<div className="slides" ref={slidesRef}>
					{slides.map(({ id, status, text, Icon, author }, i) => (
						<div
							key={i}
							className={`slide ${status}`}
							ref={slideArrRef[id]}
						>
							<button type="btn" className="btn" onClick={() => goToSlide(id)}></button>
							<p className="multicolor-text text" dangerouslySetInnerHTML={{ __html: text }} />
							<div className="author">
								<img src={Icon} alt="" className="picture" />
								<p className="author-title">{author}</p>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="navigation">
				{slides.map(({ id, status }, i) => (
					<button
						key={i}
						type="button"
						className={`btn bullet ${status}`}
						ref={bulletArrRef[id]}
						onClick={() => goToSlide(id)}
					></button>
				))}
			</div>
		</div>
	)
}
