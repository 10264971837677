import ImgShieldGroupDark from '../assets/images/decorations/icon-group-shield-dark.png'
import ImgShieldGroupLight from '../assets/images/decorations/icon-group-shield-light.png'

export default function Slide({ title, classes = '', children }) {
    return (
        <div className={`slide-wrapper ${classes}`}>
            <div className="polygon-blue">
				<div className="polygon-container">
					<div className="shape"></div>
				</div>
			</div>
			<div className="polygon-white">
				<div className="shape"></div>
			</div>

			<img src={ImgShieldGroupDark} alt="" className="shield-group shield-group--front" />
			<img src={ImgShieldGroupLight} alt="" className="shield-group" />
			<img src={ImgShieldGroupLight} alt="" className="shield-group shield-group--bottom" />

			<div className="slide-container">
                <p className="multicolor-text title" dangerouslySetInnerHTML={{ __html: title }} />
                <div className="content">{children}</div>
            </div>
        </div>
    )
}
