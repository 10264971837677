import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider, useNavigate } from "react-router-dom"
import Layout from "./components/Layout"
import Appointement from "./pages/Appointement"
import Booked from "./pages/Booked"
import Calendar from "./pages/Calendar"
import Perimeters from "./pages/Perimeters"
import Personal from "./pages/Personal"
import Solutions from "./pages/Solutions"
import Subscribe from "./pages/Subscribe"
import Subscription from "./pages/Subscription"
import SuccessMessage from "./pages/SuccessMessage"
import MonoStepFunnell from "./pages/monoStepFunnel/MonoStepFunnel"
import MonoStepFunnelSuccess from "./pages/monoStepFunnel/MonoStepFunnelSuccess"
import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"

function App() {
	const wasRandomlyRedirected = localStorage.getItem('LS_funnel_wasRedirected')
	const dispatch = useDispatch()
	const redirectionCompletedRef = useRef(false)
	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route path="/">
				<Route element={<Layout />}>
					<Route index element={<Subscribe />} />
					<Route path="perimeters" element={<Perimeters />} />
					<Route path="solutions" element={<Solutions />} />
					<Route path="appointement" element={<Appointement />} />
					<Route path="appointement/calendar" element={<Calendar />} />
					<Route path="appointement/booked" element={<Booked />} />
					<Route path="subscription" element={<Subscription />} />
					<Route path="subscription/personal" element={<Personal />} />
					<Route path="success-message" element={<SuccessMessage />} />
				</Route>
				<Route path="mono-step">
					<Route path="funnel" element={<MonoStepFunnell />} />
					<Route path="funnel/success" element={<MonoStepFunnelSuccess />} />
				</Route>
				<Route path="*" element={<Navigate to="/" replace={true} />} />
			</Route>
		)
	)

	useEffect(() => {
		const isMonoStepFunnel = window.location.href.includes("mono-step")
		const isSuccessPageMultiStepFunnel = window.location.href.includes("/success-message")
		const isReloaded = localStorage.getItem('isReloaded')

		if (isMonoStepFunnel && !window.location.href.includes("success") && !wasRandomlyRedirected) {
			window.location.replace("/")
		} else if ((!wasRandomlyRedirected && !redirectionCompletedRef.current && !isMonoStepFunnel && !isSuccessPageMultiStepFunnel) || (isMonoStepFunnel && !window.location.href.includes("mono-step/success") && !wasRandomlyRedirected && !redirectionCompletedRef.current && !isReloaded)) {
			dispatch({ type: "userData/setLoading", payload: true })

			const urlParams = window.location.search || ""
			const randownNumber = Math.random() * 10

			localStorage.setItem('LS_funnel_wasRedirected', "true")

			if (randownNumber >= 5) {
				window.location.href = `/mono-step/funnel${urlParams.includes("success") ? "" : urlParams}` 
			} else {
				window.location.href = `/${urlParams.includes("success") ? "" : urlParams}` 
			}
		} else {	
			dispatch({ type: "userData/setLoading", payload: false })
		}

		redirectionCompletedRef.current = true
	}, [dispatch, wasRandomlyRedirected])

	return (
		<main className="app">
			{(wasRandomlyRedirected || window.location.href.includes('success')) && <RouterProvider router={router} />}
		</main>
	)
}

export default App
