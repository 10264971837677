import { useTranslation } from 'react-i18next'
import Pagination from '../components/Pagination'
import Board from '../layouts/Board'
import Slide from '../layouts/Slide'
import { ReactComponent as IconPresentation } from '../assets/icons/icon-presentation.svg'
import BtnPrevious from '../components/buttons/BtnPrevious'
import Slider from '../components/Slider'
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

// if isBooked === true --> utm_step=6 else --> utm_step=4
export default function Appointement() {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const [searchParams, setSearchParams] = useSearchParams()
	const userData = useSelector(state => state.userData)

	localStorage.removeItem('dateBooked')

	useEffect(() => {
		if (userData.data.solution?.length > 0 && userData.data.utmStep !== undefined && userData.data.utmStep !== '4') {
			dispatch({ type: 'PAGES_UPDATE_USER_DATA', payload: { ...userData.data, utmStep: '4' } })
		}
	}, [dispatch, searchParams, setSearchParams, userData])

	return (
		<>
			<Board classes={'context-appointement'}>
				<Pagination text={t('pages.appointement.pagination.text')} width="calc((100% / 5) * 4" />
				<div className="content">
					<IconPresentation />
					<p className="title">{t('pages.appointement.title')}</p>
					<p className="text" dangerouslySetInnerHTML={{ __html: t('pages.appointement.text', { organizationName: userData.data.organizationName }) }} />
					<div className="content-footer">
						<div className="buttons-container">
							<Link to="/appointement/calendar?utm_step=5" replace={true} className="btn btn-filled">{t('pages.appointement.buttons.programmingDemo')}</Link>
							<Link to="/subscription?utm_step=7" replace={true} className="btn btn-filled is-disabled">{t('pages.appointement.buttons.autonomyTesting')}</Link>
						</div>
						<p className="detail">{t('pages.appointement.detail')}</p>
					</div>
				</div>
				<BtnPrevious title={t('common.buttons.previous')} link={'/solutions?utm_step=3'} field="solution" value="" utmStep="3" />
            </Board>
            <Slide
				title={t('pages.appointement.slide.title')}
				classes={'context-appointement'}
			>
				<Slider />
            </Slide>
		</>
	)
}
