export default function Pagination({ text, width }) {
	return (
		<div className="pagination">
			<div className="text">{text}</div>
			<div className="bar">
				<div className="filler" style={{ width }}></div>
			</div>
		</div>
	)
}
