import { call, put, takeLeading } from 'redux-saga/effects'
import * as Api from '../app/api'

function* initUserData(action) {
	try {
		yield put({ type: 'userData/setLoading', payload: true })
		yield put({ type: 'userData/initData', payload: { ...action.payload } })
		yield put({ type: 'userData/setLoading', payload: false })
	} catch (error) {
		yield put({ type: 'userData/setLoading', payload: false })
		console.error("[PAGES/SAGAS] initUserData error : ", error)
	}
}

function* createUserData(action) {
	try {
		yield put({ type: 'userData/setLoading', payload: true })
		const { id: userId, uuid: userUuid } = yield call(Api.createUserData, action.payload)

		if (userId) {
			yield put({ type: 'userData/addData', payload: { ...action.payload, id: userId, uuid: userUuid } })
			yield put({ type: 'userData/setLoading', payload: false })
		}
	} catch (error) {
		yield put({ type: 'userData/setLoading', payload: false })
		console.error("[PAGES/SAGAS] createUserData error : ", error)
	}
}

function* updateUserData(action) {
	try {
		yield put({ type: 'userData/setLoading', payload: true })
		const userData = yield call(Api.updateUserData, action.payload)

		// 404 code if no id in payload or id doesn't exist in db on the back-end
		if (userData.code !== undefined && userData.code === 404) {
			yield put({ type: 'userData/addData', payload: { ...action.payload, errorCode: 404 } })
		}

		// 500 code if no uuid is present in payload
		if (userData.code !== undefined && userData.code === 500) {
			yield put({ type: 'userData/addData', payload: { ...action.payload, errorCode: 500 } })
		}

		if (userData.code === undefined && Object.keys(userData).length > 0) {
			yield put({ type: 'userData/addData', payload: { ...userData, errorCode: action.payload.errorCode !== undefined ? action.payload.errorCode : null } })
		}

		yield put({ type: 'userData/setLoading', payload: false })
	} catch (error) {
		yield put({ type: 'userData/setLoading', payload: false })
		console.error("[PAGES/SAGAS] updateUserData error : ", error)
	}
}

function* deleteUserData(action) {
	try {
		if (action.payload.data?.id && action.payload.data?.uuid) {
			yield put({ type: 'userData/setLoading', payload: true })
			const status = yield call(Api.deleteUserData, { id: action.payload.data.id, uuid: action.payload.data.uuid })

			if (status === 404) {
				yield put({ type: 'userData/addData', payload: { ...action.payload.data, errorCode: 404 } })
			} else if (status === 204) {
				const status = yield call(Api.sendUserData, action.payload.data)

				if (status === 201) {
					yield put({ type: 'userData/deleteData', payload: action.payload })
				} else if (status === 409) {
					yield put({ type: 'userData/addData', payload: { ...action.payload.data, errorCode: 409, id: null } })
				}
			}

			yield put({ type: 'userData/setLoading', payload: false })
		}
	} catch (error) {
		yield put({ type: 'userData/setLoading', payload: false })
		console.error("[PAGES/SAGAS] deleteUserData error : ", error)
	}
}

export default function* pagesSagas() {
	yield takeLeading('PAGES_CREATE_USER_DATA', createUserData)
	yield takeLeading('PAGES_UPDATE_USER_DATA', updateUserData)
	yield takeLeading('PAGES_DELETE_USER_DATA', deleteUserData)
	yield takeLeading('PAGES_INIT_USER_DATA', initUserData)
}
