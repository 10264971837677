import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MiniLoader from '../components/animations/MiniLoader'
import BtnPrevious from '../components/buttons/BtnPrevious'
import Board from '../layouts/Board'
import Slide from '../layouts/Slide'

export default function Calendar() {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const userData = useSelector(state => state.userData)
	let iframeUrl = 'https://calendly.com/e-merveille/lockself-demo?back=1'

	if (userData.data.estimatedUser === '100-500') {
		iframeUrl = 'https://calendly.com/b-cabaye-lockself/lockself-demo?back=1'
	} else if (userData.data.estimatedUser === '500-1000') {
		iframeUrl = 'https://calendly.com/alexandra-arias-lockself/lockself-demo?back=1'
	} else if (userData.data.estimatedUser === '1000+') {
		iframeUrl = 'https://calendly.com/s-azouz-lockself/lockself-demo?back=1'
	}

	useEffect(() => {
		if (userData.data.utmStep !== '5') dispatch({ type: 'PAGES_UPDATE_USER_DATA', payload: { ...userData.data, utmStep: '5' } })
	}, [dispatch, userData])

	return (
		<>
			<Board classes={'context-calendar'}>
				<div className="calendar-container">
					<MiniLoader />
					<iframe src={`${iframeUrl}&embed_type=inline&embed_domain=1`} frameBorder="0" height="100%" width="100%" title="Iframe calendly" />
				</div>
				<BtnPrevious title={t('common.buttons.previous')} link={'/appointement?utm_step=4'} />
            </Board>
            <Slide
				title={t('pages.calendar.slide.title')}
				classes={'context-calendar'}
			/>
		</>
	)
}
