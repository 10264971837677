import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import BtnFilled from '../components/buttons/BtnFilled'
import Form from '../components/Form'
import Input from '../components/Input'
import Pagination from '../components/Pagination'
import Board from '../layouts/Board'
import Slide from '../layouts/Slide'
import BadgeChiffrement from '../assets/images/badges/badge-chiffrement.png'
import BadgeGestion from '../assets/images/badges/badge-gestion.png'
import BadgeLeader from '../assets/images/badges/badge-leader.png'
import BadgeVisasecu from '../assets/images/badges/visasecu.png'
import { useDispatch, useSelector } from 'react-redux'
import BtnPrevious from '../components/buttons/BtnPrevious'

export default function Subscription() {
	const dispatch = useDispatch()
	const [t] = useTranslation()
	const navigate = useNavigate()
	const userData = useSelector(state => state.userData)
	const [fakeEmail, setFakeEmail] = useState(false)
	const [invalidEmail, setInvalidEmail] = useState(userData.errorCode)
	const [data, setData] = useState({
		email: userData.data?.email ? userData.data?.email : ''
	})

	// FUNCTIONS
	const handleOnSubmit = (event) => {
		event.preventDefault()

		if (!fakeEmail && (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(data.email.toLowerCase()))) {
			dispatch({ type: 'PAGES_UPDATE_USER_DATA', payload: { ...userData.data, ...data, utmStep: '8', errorCode: null } })
			navigate('/subscription/personal?utm_step=8', { replace: true })
		}
	}

	const handleOnChange = ({ target: { name, value } }) => {
		if (name === 'email') {
			if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value.toLowerCase()))) {
				setFakeEmail(true)
			} else {
				setFakeEmail(false)
			}
		}

		setInvalidEmail(false)
		setData({
			...data,
			[name]: value
		})
	}

	// EFFECTS
	useEffect(() => {
		if (userData?.data?.id && userData.data.utmStep !== '7' && userData.data.solution?.length > 0) {
			dispatch({ type: 'PAGES_UPDATE_USER_DATA', payload: { ...userData.data, utmStep: '7', errorCode: userData.errorCode } })
		}

		if (userData.errorCode === 409 && userData.data.id === null) {
			dispatch({ type: 'PAGES_CREATE_USER_DATA', payload: { ...userData.data } })
		}
	}, [dispatch, userData])

	return (
		<>
            <Board classes="context-subscription">
				<Pagination text={t('pages.subscription.pagination.text')} width="calc(100% / 3)" />
				<div className="content-container">
					<div className="content-header">
						<p className="title">{t('pages.subscription.board.title')}</p>
						<p className="text">{t('pages.subscription.board.text')}</p>
					</div>
					<Form onSubmitMethod={handleOnSubmit}>
						<Input
							autoFocus={true}
							type="email"
							onChangeMethod={handleOnChange}
							name="email"
							value={data.email}
							placeholder={t('forms.email.placeholder')}
							error={{
								isActive: fakeEmail,
								text: t('forms.email.errors.wrongMail')
							}}
							invalid={{
								status: invalidEmail,
								text: t('forms.email.errors.invalid')
							}}
						/>
						<div className="form-footer">
							<Input
								type="checkbox"
								name="cgv"
								label={t('forms.cgv.label', { link: '#' })}
								value={data.cgv}
							/>
							<BtnFilled type="submit" title={t('common.buttons.confirmEmail')} />
						</div>
					</Form>
				</div>
				{(!localStorage.getItem('dateBooked') && userData.data.estimatedUser !== '1-25') && <Link to="/appointement?utm_step=4" replace={true} className="btn btn-arrow btn-previous">{t('common.buttons.previous')}</Link>}
				{(!localStorage.getItem('dateBooked') && userData.data.estimatedUser === '1-25') && <BtnPrevious title={t('common.buttons.previous')} link={'/solutions?utm_step=3'} field="solution" value="" utmStep="3" />}
            </Board>
            <Slide
				title={t('pages.subscription.slide.title')}
				classes={'context-subscription'}
			>
				<div className="badges-gallery">
					<div className="img-container">
						<img src={BadgeChiffrement} alt="" />
					</div>
					<div className="img-container">
						<img src={BadgeLeader} alt="" />
					</div>
					<div className="img-container">
						<img src={BadgeGestion} alt="" />
					</div>
				</div>
				<img src={BadgeVisasecu} alt="" />
            </Slide>
        </>
	)
}
