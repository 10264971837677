import { useTranslation } from 'react-i18next'
import Board from '../layouts/Board'
import Slide from '../layouts/Slide'
import Form from '../components/Form'
import Input from '../components/Input'
import { useEffect, useState } from 'react'
import BtnFilled from '../components/buttons/BtnFilled'
import { useDispatch, useSelector } from 'react-redux'
import Popin from '../components/Popin'
import Gallery from '../components/Gallery'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function Subscribe() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [searchParams, setSearchParams] = useSearchParams();
	const userData = useSelector(state => state.userData)
	const [t] = useTranslation()
    const [data, setData] = useState({
        organizationName: searchParams.get('organizationName') ? searchParams.get('organizationName') : ''
    })
	const [error, setError] = useState(userData?.errorCode === 404 || localStorage.getItem('isSessionExpired'))

	if (!searchParams.get('utm_step')) {
		searchParams.set('utm_step', '1')

		window.location.search = searchParams
	}

    //  FUNCTIONS
    const handleOnSubmit = event => {
        event.preventDefault()

		dispatch({ type: 'PAGES_CREATE_USER_DATA', payload: { ...userData.data, ...data, utmStep: '2' } })
		navigate('/perimeters?utm_step=2', { replace: true })
    }

    const handleOnChange = ({ target: { value, name } }) => {
        setData({
            ...data,
            [name]: value
        })
		setError(false)

		if (localStorage.getItem('isSessionExpired')) localStorage.removeItem('isSessionExpired')
    }

	// EFFECTS
	useEffect(() => {
		let newUserData = {}

		if (searchParams.get('utm_source')) {
			localStorage.setItem('utm_source', searchParams.get('utm_source'))
			newUserData = { ...newUserData, utmSource: searchParams.get('utm_source') }
		} else if (localStorage.getItem('utm_source')) {
			newUserData = { ...newUserData, utmSource: localStorage.getItem('utm_source') }
		}

		if (searchParams.get('utm_campaign')) {
			localStorage.setItem('utm_campaign', searchParams.get('utm_campaign'))
			newUserData = { ...newUserData, utmCampaign: searchParams.get('utm_campaign') }
		} else if (localStorage.getItem('utm_campaign')) {
			newUserData = { ...newUserData, utmCampaign: localStorage.getItem('utm_campaign') }
		}

		if (searchParams.get('utm_content')) {
			localStorage.setItem('utm_content', searchParams.get('utm_content'))
			newUserData = { ...newUserData, utmContent: searchParams.get('utm_content') }
		} else if (localStorage.getItem('utm_content')) {
			newUserData = { ...newUserData, utmContent: localStorage.getItem('utm_content') }
		}

		if (searchParams.get('organizationName')) {
			localStorage.setItem('organizationName', searchParams.get('organizationName'))
			newUserData = { ...newUserData, organizationName: searchParams.get('organizationName') }
		} else if (localStorage.getItem('organizationName')) {
			newUserData = { ...newUserData, organizationName: localStorage.getItem('organizationName') }
		}

		if (searchParams.get('utm_source') || searchParams.get('utm_content') || searchParams.get('utm_campaign') || searchParams.get('organizationName')) {
			setSearchParams(searchParams.get('utm_step') ? { utm_step: searchParams.get('utm_step') } : {}, { replace: true })
		} else if (userData.loading === false && Object.keys(newUserData).length > 0) {
			// clear fields stored in localStorage
			if (localStorage.getItem('utm_source')) localStorage.removeItem('utm_source')
			if (localStorage.getItem('utm_content')) localStorage.removeItem('utm_content')
			if (localStorage.getItem('utm_campaign')) localStorage.removeItem('utm_campaign')
			if (localStorage.getItem('organizationName')) localStorage.removeItem('organizationName')

			dispatch({ type: 'PAGES_INIT_USER_DATA', payload: { ...userData.data, ...newUserData } })
		}
	}, [dispatch, searchParams, setSearchParams, userData])

	useEffect(() => {
		dispatch({ type: 'PAGES_INIT_USER_DATA' })
	}, [dispatch])

    return (
        <>
            <Board classes={'context-information'}>
				<Form onSubmitMethod={handleOnSubmit}>
					<Input
						autoFocus={true}
						onChangeMethod={handleOnChange}
						name="organizationName"
						label={t('forms.organizationName.label')}
						value={data.organizationName}
						placeholder={t('forms.organizationName.placeholder')}
						error={{
							isActive: error,
							text: t('forms.organizationName.errors.sessionExpired')
						}}
					/>
					<BtnFilled type="submit" title={t('common.buttons.confirm')} />
				</Form>
            </Board>
            <Slide
				title={t('pages.information.slide.title1')}
				classes={'context-information'}
			>
                <Gallery />
            </Slide>
			<Popin text={t('pages.information.popin')} />
        </>
    )
}
