import Lottie from 'react-lottie';

export default function AnimIcon(props) {
	const defaultOptions = {
		loop: props.loop ? props.loop : false,
		autoplay: props.autoplay ? props.autoplay : false,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	}

	return (
		<div
			{...(props.action ? {onClick: () => props.action()} : null)}
		>
			<Lottie
				options={{...defaultOptions, animationData: props.json}}
				direction={props.toggled ? (props.toggled ? 1 : -1): -1}
				width={props.width}
				speed={props.speed}
				style={{ display: "flex" }}
			/>
		</div>
	)
}
