import { useTranslation } from 'react-i18next'
import Board from '../layouts/Board'
import Slide from '../layouts/Slide'
import { ReactComponent as IconPresentation } from '../assets/icons/icon-presentation.svg'
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Popin from '../components/Popin'
import { useEffect } from 'react'

export default function Booked() {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const [searchParams, setSearchParams] = useSearchParams()
	const { organizationName } = useSelector(state => state.userData)
	const userData = useSelector(state => state.userData)

	localStorage.setItem('dateBooked', true)

	useEffect(() => {
		if (userData.data.solution?.length > 0 && userData.data.utmStep !== undefined && userData.data.utmStep !== '6') {
			let data = { ...userData.data, utmStep: '6' }

			if (searchParams.get('invitee_full_name')) {
				data = { ...data, lastName: searchParams.get('invitee_full_name') }
			}

			if (searchParams.get('invitee_email')) {
				data = { ...data, email: searchParams.get('invitee_email') }
			}

			dispatch({ type: 'PAGES_UPDATE_USER_DATA', payload: data })
			if (searchParams.get('invitee_full_name') && searchParams.get('invitee_email')) setSearchParams({ utm_step: '6' }, { replace: true })
		}
	}, [dispatch, searchParams, setSearchParams, userData])

	return (
		<>
			<Board classes={'context-appointement'}>
				<div className="content">
					<IconPresentation />
					<p className="title">{t('pages.appointement.booked.title', { organizationName })}</p>
					<p className="text" dangerouslySetInnerHTML={{ __html: t('pages.appointement.booked.text') }} />
					<div className="content-footer">
						<p className="detail">{t('pages.appointement.booked.detail')}</p>
						<div className="buttons-container is-single">
							<Link to="/subscription?utm_step=7" replace={true} className="btn btn-filled">{t('pages.appointement.booked.buttons.finalise')}</Link>
						</div>
					</div>
				</div>
            </Board>
            <Slide
				title={t('pages.appointement.booked.slide.title')}
				classes={'context-appointement'}
			>
				<div className="paragraphs">
					<div className="item">
						<p className="number" dangerouslySetInnerHTML={{ __html: t('pages.appointement.booked.slide.paragraphs.paragraph1.number') }} />
						<p className="multicolor-text text" dangerouslySetInnerHTML={{ __html: t('pages.appointement.booked.slide.paragraphs.paragraph1.text') }} />
					</div>
					<div className="item">
						<p className="number" dangerouslySetInnerHTML={{ __html: t('pages.appointement.booked.slide.paragraphs.paragraph2.number') }} />
						<p className="multicolor-text text" dangerouslySetInnerHTML={{ __html: t('pages.appointement.booked.slide.paragraphs.paragraph2.text') }} />
					</div>
				</div>
				<div className="small-text">{t('pages.appointement.booked.slide.smallText')}</div>
            </Slide>
			<Popin text={t('pages.appointement.booked.popin')} />
		</>
	)
}
