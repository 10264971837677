import { useTranslation } from 'react-i18next'
import Board from '../layouts/Board'
import { ReactComponent as IconSuccess } from '../assets/icons/icon-check-green.svg'
import ShieldGroupLight from '../assets/images/decorations/icon-group-shield-light.png'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { windowReload } from '../actions/generalUtils'

export default function SuccessMessage() {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const userData = useSelector(state => state.userData)

	useEffect(() => {
		if (localStorage.getItem('userData') && userData?.data && userData?.data?.id && userData.errorCode === null && userData.data.utmStep === "9" && userData.data.phoneNumber?.length > 0) {
			dispatch({ type: 'PAGES_DELETE_USER_DATA', payload: userData })
		} else {
			windowReload()
		}

		if (userData.errorCode !== null && userData.errorCode === 409) {
			navigate('/subscription?utm_step=7', { replace: true })
		}

		const isReloaded = localStorage.getItem('isReloaded')

		if (isReloaded) {
			localStorage.removeItem('LS_funnel_wasRedirected')
		}
	}, [dispatch, navigate, userData])

	return (
		<>
			<Board classes={'context-success-message'}>
				<div className="content">
					<p className="title" dangerouslySetInnerHTML={{ __html: t('pages.successMessage.title') }} />
					<p className="subtitle">{t('pages.successMessage.subtitle')}</p>
					<p className="text" dangerouslySetInnerHTML={{ __html: t('pages.successMessage.text') }} />
					<div className="hint">
						<div className="hint-icon">
							<IconSuccess />
						</div>
						<p className="hint-text">{t('pages.successMessage.hint')}</p>
					</div>
				</div>
				<div className="shields-img-container">
					<img src={ShieldGroupLight} alt="" />
				</div>
			</Board>
			<div className="shields-img-container">
				<img src={ShieldGroupLight} alt="" />
			</div>
		</>
	)
}
